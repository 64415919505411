<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-03-23 15:58:38
 * @LastEditTime: 2021-04-13 15:58:44
 * @LastEditors: Chp
 * @Reference: 
-->

<template>
  <!-- 基金会介绍 -->
  <div class="institutionalHonor">
    <LogoTitle msg="机构荣誉"></LogoTitle>
    <RichArticle :html="html"></RichArticle>
  </div>
</template>

<script>
export default {
  data () {
    return {
      html: ''
    }
  },
  created () {
    this.getDetail();
  },
  methods: {
    getDetail () {
      let params = {
        categoryId: '1582987844312719361'
      }
      this.$api.getCategoryDetail(params).then(res => {
        this.html = res.data.data.contentDetails
      })
    }
  },
}
</script>

<style scoped lang='scss'>
</style>